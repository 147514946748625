exports.components = {
  "component---src-components-blog-all-js": () => import("./../../../src/components/blogAll.js" /* webpackChunkName: "component---src-components-blog-all-js" */),
  "component---src-components-blog-category-list-js": () => import("./../../../src/components/blogCategoryList.js" /* webpackChunkName: "component---src-components-blog-category-list-js" */),
  "component---src-components-blog-post-js": () => import("./../../../src/components/blogPost.js" /* webpackChunkName: "component---src-components-blog-post-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-it-docs-index-js": () => import("./../../../src/pages/IT-docs/index.js" /* webpackChunkName: "component---src-pages-it-docs-index-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-pages-web-app-dev-index-js": () => import("./../../../src/pages/web-app-dev/index.js" /* webpackChunkName: "component---src-pages-web-app-dev-index-js" */)
}

