/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

const $ = require("jquery")

export const onRouteUpdate = () => {

    const hash = typeof window !== 'undefined' ? window.location.hash : '';

    var contactHash = "";
    if (hash === "#keng") {
        contactHash = "keng";
    }

    $(function(){
        var cate = $('#blogPost').attr("data-cate");
        var elem = '';
        if (cate === 'it-docs') {
            cate = "IT-docs";
            elem = '<div id="category_ad"><a href="/' + cate + '" class="' + cate + '"><span>ワンダーウォールの制作ノウハウが、あなたのビジネスに貢献します。</span><div class="wrapper"><figure class="banner"></figure></div></a></div>';
        } else if (cate === 'web-app-dev') {
            cate = "web-app-dev";
            elem = '<div id="category_ad"><a href="/' + cate + '" class="' + cate + '"><span>ワンダーウォールの制作ノウハウが、あなたのビジネスに貢献します。</span><div class="wrapper"><figure class="banner"></figure></div></a></div>';
        }
        if (elem !== '') {
            $(elem).replaceAll('.wp-block-separator');
        }
        console.log('I found!');

        if (contactHash !== "") {
            visiblePersonInfo();
        } else {
            invisiblePersoninfo();
        }
     });

     const visiblePersonInfo = function() {
        getContactJson(contactHash);
    
        $('.ww-contact').attr({
            'person': contactHash
        });
        $(".ww-contact").toggle(200);
        $('.ww-contact-wrapper').css('display', 'block');
        $('.contact-item-position-7').css('display', 'none');
    }
    
    const invisiblePersoninfo = function() {
        $(".ww-contact").hide(200);
        $('.ww-contact-wrapper').css('display', 'none');
    }
    
    $('.ww-globalnav-item .contact-icon').click(function() {
        if (contactHash !== "") {
            if ($(".ww-contact").css('display') !== 'block') {
                visiblePersonInfo();
            } else {
                invisiblePersoninfo();
            }
        } else {
            $(".ww-contact").toggle(200);
        }
    });

    $(document).click(function(event) {
        if (!$(event.target).closest('.ww-contact').length && !$(event.target).closest('.contact-icon').length) {
            invisiblePersoninfo();
            $(".ww-contact").hide(200);
        }
    });
    
    const getContactJson = function(contactHash) {
        var jsonUrl = 'https://wonderwall.net/lib/contact-' + contactHash + '.json';
        $.ajax({
            url: jsonUrl,
            dataType: 'json',
            data: {
                name: 'contact'
            },
            success: function(data) {
                perseJson(data.contact);
            }
        });
    }
    
    function perseJson(jsonArray) {
        var data = jsonArray[0];
        var profileImage = 'url(' + data.profileImage + ') -20px -70px no-repeat';
        $('#ww-globalnav .ww-contact .profile-image').css({
            'background': profileImage,
            'background-size': '120%'
        });
    
        $('#ww-globalnav .ww-contact .person-name-main .person-name-first').empty().append(data.personNameMain[0].personNameFirst);
        $('#ww-globalnav .ww-contact .person-name-main .person-name-last').empty().append(data.personNameMain[0].personNameLast);
    
        $('#ww-globalnav .ww-contact .person-name-sub .person-name-first').empty().append(data.personNameSub[0].personNameFirst);
        $('#ww-globalnav .ww-contact .person-name-sub .person-name-last').empty().append(data.personNameSub[0].personNameLast);
    
        $('#ww-globalnav .ww-contact .contact-item-email .email-address-name').empty().append(data.contactItemEmail[0].emailAddressName);
        $('#ww-globalnav .ww-contact .contact-item-email .email-address-domain').empty().append(data.contactItemEmail[0].emailAddressDomain);
        var emailAddr = data.contactItemEmail[0].emailAddressName + '@' + data.contactItemEmail[0].emailAddressDomain;
        $('#ww-globalnav .ww-contact .contact-item-email .email-address-complete').attr('href', 'mailto:' + emailAddr);
    
        $('#ww-globalnav .ww-contact .contact-item-telephone .telephone-number-1').empty().append(data.contactItemTelephone[0].telephoneNumber1);
        $('#ww-globalnav .ww-contact .contact-item-telephone .telephone-number-2').empty().append(data.contactItemTelephone[0].telephoneNumber2);
        $('#ww-globalnav .ww-contact .contact-item-telephone .telephone-number-3').empty().append(data.contactItemTelephone[0].telephoneNumber3);
        var telNo = data.contactItemTelephone[0].telephoneNumber1 + data.contactItemTelephone[0].telephoneNumber2 + data.contactItemTelephone[0].telephoneNumber3;
        $('#ww-globalnav .ww-contact .contact-item-telephone .telephone-number-complete').attr('href', 'tel:' + telNo);
        $('#ww-globalnav .ww-contact .contact-item-telephone .telephone-type-device').empty().append(data.contactItemTelephone[0].telephoneTypeDevice);
        $('#ww-globalnav .ww-contact .contact-item-telephone .telephone-type-carrier').empty().append(data.contactItemTelephone[0].telephoneTypeCarrier);
    
        $('#ww-globalnav .ww-contact .contact-item-address .address-zip-postal-number').empty().append(data.contactItemAddress[0].addressZipPostalNumber);
        $('#ww-globalnav .ww-contact .contact-item-address .address-prefecture-name').empty().append(data.contactItemAddress[0].addressPrefectureName);
        $('#ww-globalnav .ww-contact .contact-item-address .address-city-name').empty().append(data.contactItemAddress[0].addressCityName);
        $('#ww-globalnav .ww-contact .contact-item-address .address-area-name').empty().append(data.contactItemAddress[0].addressAreaName);
        var mapURL = "https://www.google.co.jp/maps/place/" + data.contactItemAddress[0].addressZipPostalNumber + " " + data.contactItemAddress[0].addressPrefectureName + data.contactItemAddress[0].addressCityName + data.contactItemAddress[0].addressAreaName;
        $('#ww-globalnav .ww-contact .contact-item-map-url').attr('href', mapURL);
    
        $('#ww-globalnav .ww-contact .contact-item-sns .sns-facebook .caption').empty().append(data.contactItemSns[0].snsFacebook);
        $('#ww-globalnav .ww-contact .contact-item-sns .sns-facebook').attr('href', 'https://www.facebook.com/' + data.contactItemSns[0].snsFacebook);
        $('#ww-globalnav .ww-contact .contact-item-sns .sns-twitter .caption').empty().append('@' + data.contactItemSns[0].snsTwitter);
        $('#ww-globalnav .ww-contact .contact-item-sns .sns-twitter').attr('href', 'https://twitter.com/' + data.contactItemSns[0].snsTwitter);
    }

}
